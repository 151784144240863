





























import Uploader from "./Uploader.vue";
import { defineComponent, ref } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "UploadDocumentation",
  components: { Uploader },
  setup: () => {
    const tab = ref("");
    const items = ref([
      {
        title: "Legitimasjon",
        documentType: "identifications" as Learnlink.File.DocumentCollectionRef,
      },
      {
        title: "Politiattest",
        documentType: "policeRecords" as Learnlink.File.DocumentCollectionRef,
      },
      {
        title: "Vitnemål",
        documentType: "certificates" as Learnlink.File.DocumentCollectionRef,
      },
    ]);

    return {
      tab,
      items,
    };
  },
});
