const GUCCI_STAGING_EMAILS = [
  "jb@learnlink.no",
  "jb+testkunde@learnlink.no",
  "xk@learnlink.no",
  "jr@learnlink.no",
  "jk@learnlink.no",
  "tt@learnlink.no"
];
const GUCCI_BETA_EMAIL_CHARS = ["a", "e", "i", "o", "u", "y"];

const shouldShowGucciStaging = (email: string) => {
  return GUCCI_STAGING_EMAILS.includes(email);
};

const shouldShowGucciBeta = (email: string, betaTester: boolean) => {
  return shouldShowGucciStaging(email) ||
    (GUCCI_BETA_EMAIL_CHARS.some(char => email.startsWith(char))) ||
    betaTester;
};

const shouldShowGucciBetaForSellers = (email: string, seller: boolean, betaTester: boolean) => {
  return seller && shouldShowGucciBeta(email, betaTester);
};

export default function featureToggles(email: string, seller: boolean, betaTester: boolean): {
  gucciStaging: boolean;
  gucciBeta: boolean;
  gucciBetaSeller: boolean;
} {
  return {
    gucciStaging: shouldShowGucciStaging(email),
    gucciBeta: shouldShowGucciBeta(email, betaTester),
    gucciBetaSeller: shouldShowGucciBetaForSellers(email, seller, betaTester)
  };
}
