


























































import analytics from "@/services/analytics";
import { defineComponent, ref } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import stripe from "@/services/stripe";
import { createExternalAccount } from "@/api/stripeService";
import { useNotifier } from "@/providers/notifier";

type Currency = "nok" | "eur" | "sek" | "usd";

const setCurrency = (country: string): Currency => {
  if (country === "FR") return "eur";
  else if (country === "SE") return "sek";
  else if (country === "US") return "usd";
  return "nok";
};

export default defineComponent({
  name: "AddExistingAccount",
  setup: (props, { emit }) => {
    const accountHolderName = ref("");
    const accountNumber = ref("");
    const form = ref();
    const isLoading = ref(false);
    const validForm = ref(false);

    const formRules = {
      required: (v: string) => !!v || "Obligatorisk felt",
    };

    const notify = useNotifier();

    const addExternalAccount = async() => {
      const currency = setCurrency(userModule.state.user.country);
      const additionalInfo = {
        country: userModule.state.user.country || "NO",
        currency,
        account_number: accountNumber.value,
        account_holder_name: accountHolderName.value,
        account_holder_type: "individual",
      };

      try {
        form.value.validate();
        if (validForm.value) {
          isLoading.value = true;
          const tokenRes = await stripe.api.createToken("bank_account", additionalInfo);

          if (tokenRes.token) {
            const newAccount = await createExternalAccount(userModule.state.userId, tokenRes.token.id);
            if (newAccount) emit("accountAdded", newAccount);
          }
          else throw new Error("Could not generate token to create stripe external account");

          notify({
            title: "Bankkonto lagt til!",
            message: "",
            type: "success",
          });
          analytics.track("addAccount");
          form.value.reset();
          isLoading.value = false;
        }
      }
      catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        notify({
          title: "Oops",
          message: "Noe gikk galt. Ta kontakt med support om problemet vedvarer",
          type: "error",
        });
        isLoading.value = false;
      }
    };

    return {
      accountHolderName,
      accountNumber,
      addExternalAccount,
      form,
      formRules,
      isLoading,
      validForm,
    };
  },
});
