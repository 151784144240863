





















































import { defineComponent, PropType, ref } from "@vue/composition-api";
import { deleteStripeExternalAccount, setAccountAsDefault } from "@/api/stripeService";
import { userModule } from "@/store/modules/user";
import { useNotifier } from "@/providers/notifier";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "StripeAccountTemplate",
  props: {
    account: {
      type: Object as PropType<Learnlink.Stripe.BankAccount>,
      required: true,
    },
    isDefault: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup: (props, { emit }) => {
    const notify = useNotifier();
    const defaultLoading = ref(false);
    const deleteLoading = ref(false);

    const deleteAccount = async() => {
      try {
        deleteLoading.value = true;
        await deleteStripeExternalAccount(userModule.state.userId, props.account.id);
        notify({
          title: "Konto slettet",
          message: "",
          type: "success",
        });
        emit("deleted", props.account.id);
        deleteLoading.value = false;
      }
      catch (e) {
        notify({
          title: "Oops",
          message: "Noe gikk galt, ta kontakt med support om problemet vedvarer",
          type: "error",
        });
        deleteLoading.value = false;
      }
    };

    const setAsDefault = async() => {
      try {
        defaultLoading.value = true;
        await setAccountAsDefault(userModule.state.userId, props.account.id);
        notify({
          title: "Informasjonen ble oppdatert",
          message: "Utbetalinger vil nå bli utbetalt til denne kontoen",
          type: "success",
        });
        emit("updated", props.account.id);
        defaultLoading.value = false;
      }
      catch (e) {
        notify({
          title: "Oops",
          message: "Noe gikk galt, ta kontakt med support om problemet vedvarer",
          type: "error",
        });
        defaultLoading.value = false;
      }
    };

    return {
      deleteAccount,
      deleteLoading,
      defaultLoading,
      setAsDefault,
    };
  },
});
