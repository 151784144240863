
































































































































import ConnectDiscordButton from "@/components/settings/ConnectDiscordButton.vue";
import { computed, defineComponent } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import AboutMe from "@/components/settings/AboutMe.vue";
import DeleteAccount from "@/components/settings/DeleteAccount.vue";
import EditProfile from "@/components/profile/EditProfile.vue";
import EmailAndPassword from "@/components/settings/EmailAndPassword.vue";
import ExistingCards from "@/components/settings/stripeSettings/ExistingCards.vue";
import ExternalAccounts from "@/components/settings/stripeSettings/ExternalAccounts.vue";
import Notifications from "@/components/settings/notifications/Notifications.vue";
import UploadDocumentation from "@/components/settings/upload/UploadDocumentation.vue";
import featureToggles from "@/helpers/featureToggles";

export default defineComponent({
  name: "Settings",
  components: {
    ConnectDiscordButton,
    DeleteAccount,
    EditProfile,
    AboutMe,
    EmailAndPassword,
    ExistingCards,
    ExternalAccounts,
    Notifications,
    UploadDocumentation,
  },
  setup: () => {
    const showFeatures = computed(() => featureToggles(userModule.state.account.email, userModule.state.user.seller, userModule.state.user.betaTester).gucciBetaSeller);

    return {
      discordUID: computed(() => userModule.state.user.discordUID),
      isSeller: computed(() => userModule.state.user.seller),
      isStudent: computed(() => userModule.state.user.role === "student"),
      showFeatures,
    };
  },
});
