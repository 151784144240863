












































import CardOrInvoiceSwitch from "@/components/settings/stripeSettings/CardOrInvoiceSwitch.vue";
import Learnlink from "@learnlink/interfaces";
import AddCard from "./AddCard.vue";
import { getStripeCards } from "@/api/stripeService";
import useAsyncData from "@/hooks/useAsyncData";
import { userModule } from "@/store/modules/user";
import StripeCardTemplate from "./StripeCardTemplate.vue";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ExistingCards",
  components: { CardOrInvoiceSwitch, AddCard, StripeCardTemplate },
  setup: () => {
    const { isLoading, data: stripeCardView, hasError } = useAsyncData(() => getStripeCards(userModule.state.userId));

    function deleteCard(deletedCardID: string) {
      if (stripeCardView.value) {
        const allCards = stripeCardView.value.cards;
        stripeCardView.value.cards = allCards.filter(card => card.id !== deletedCardID);
      }
    }

    function addCard(newCard: Learnlink.Stripe.Card) {
      if (stripeCardView.value) {
        stripeCardView.value.cards.push(newCard);
      }
    }

    function handleUpdate(cardID: string) {
      if (stripeCardView.value) {
        stripeCardView.value.default_source = cardID;
      }
    }

    const noCards = computed(() => !stripeCardView.value?.cards?.length);

    return {
      addCard,
      defaultCard: (cardID: string) => {
        if (stripeCardView.value) return stripeCardView.value.default_source === cardID;
        return false;
      },
      deleteCard,
      hasError,
      handleUpdate,
      isLoading,
      noCards,
      stripeCardView,
    };
  },
});

