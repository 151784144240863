





































import Learnlink from "@learnlink/interfaces";
import AddExternalAccount from "./AddExternalAccount.vue";
import { defineComponent } from "@vue/composition-api";
import { getStripeAccounts } from "@/api/stripeService";
import { userModule } from "@/store/modules/user";
import useAsyncData from "@/hooks/useAsyncData";
import StripeAccountTemplate from "./StripeAccountTemplate.vue";

export default defineComponent({
  name: "ExistingAccounts",
  components: { AddExternalAccount, StripeAccountTemplate },
  setup: () => {
    const { isLoading, data: stripeAccountView, hasError } = useAsyncData(() => getStripeAccounts(userModule.state.userId));

    const handleDelete = (accountID: string) => {
      if (stripeAccountView.value) {
        const allAccounts = stripeAccountView.value.bankAccounts;
        stripeAccountView.value.bankAccounts = allAccounts.filter(acc => acc.id !== accountID);
      }
    };

    const handleNewAccount = (newAccount: Learnlink.Stripe.BankAccount) => {
      if (stripeAccountView.value) {
        stripeAccountView.value.bankAccounts.push(newAccount);
      }
    };

    const handleUpdate = (accountID: string) => {
      if (stripeAccountView.value) stripeAccountView.value.defaultBankAccount = accountID;
    };

    return {
      defaultAccount: (accountID: string) => {
        if (stripeAccountView.value) return stripeAccountView.value.defaultBankAccount === accountID;
        return false;
      },
      handleDelete,
      handleNewAccount,
      handleUpdate,
      hasError,
      isLoading,
      stripeAccountView,
    };
  },
});

