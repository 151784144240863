

















































import { createStripeCard } from "@/api/stripeService";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import analytics from "@/services/analytics";
import stripe from "@/services/stripe";

export default defineComponent({
  name: "AddCard",
  setup: (props, { emit }) => {
    const isLoading = ref(false);
    const notify = useNotifier();

    const elements = stripe.api.elements({ locale: "no" });

    const card = elements.create("card", {
      iconStyle: "solid",
      style: {
        base: {
          color: "#32325d",
          fontFamily: "\"Helvetica Neue\", Helvetica, sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
      classes: {
        focus: "is-focused",
        empty: "is-empty",
      },
    });
    onMounted(() => {
      card.mount("#card-element");

      card.on("change", ({ error }) => {
        const displayError = document.getElementById("card-errors");
        if (error) {
          if (displayError) displayError.textContent = error.message;
          notify({
            title: "Feil",
            message: error.message,
            type: "error",
          });
        }
        else if (displayError) displayError.textContent = "";
      });
    });

    const addCard = async() => {
      try {
        isLoading.value = true;

        // Test card number: 4000005780000007
        const tokenRes = await stripe.api.createToken(card);
        if (tokenRes.token) {
          const newCard = await createStripeCard(userModule.state.userId, tokenRes.token.id);
          if (newCard) emit("cardAdded", newCard);
        }
        else throw new Error("Could not genereate token to create stripe card");
        notify({
          title: "Kort lagt til!",
          message: "",
          type: "success",
        });
        isLoading.value = false;
        analytics.track("addCard");
      }
      catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        notify({
          title: "Oops!",
          message: "Noe gikk galt, ta kontakt med support om problemet vedvarer",
          type: "success",
        });
        isLoading.value = false;
      }
    };

    return {
      addCard,
      isLoading,
    };
  },
});

