
























import { getCollectionMethod, getMembership, updateCollectionMethod } from "@/api/membershipService";
import handleError from "@/helpers/errors";
import { useNotifier } from "@/providers/notifier";
import { computed, defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "CardOrInvoiceSwitch",
  props: {
    noCards: {
      type: Boolean as PropType<boolean>,
      required: true,
    }
  },
  setup() {
    const vm = reactive({
      activeCollectionMethod: "" as Learnlink.Stripe.CollectionMethod,
      cardChosen: false,
      loading: true,
      m: {} as Learnlink.Membership.default,
    });

    onMounted(async() => {
      vm.m = await getMembership(userModule.state.userId);
      if (vm.m.subscription) {
        const collectionMethod = await getCollectionMethod(userModule.state.userId);
        if (collectionMethod === "charge_automatically") vm.cardChosen = true;
        vm.activeCollectionMethod = collectionMethod;
      }
      vm.loading = false;
    });

    const noSubscription = computed(() => !vm.m.subscription);
    const notify = useNotifier();

    async function update() {
      vm.loading = true;
      try {
        const collectionMethod: Learnlink.Stripe.CollectionMethod = vm.cardChosen ? "charge_automatically" : "send_invoice";
        await updateCollectionMethod(userModule.state.userId, collectionMethod);
        vm.activeCollectionMethod = collectionMethod;
        notify({
          title: "Oppdatert! 🥳",
          message: vm.cardChosen
            ? "Du vil nå bli trukket automatisk fra kortet du har valgt som standard-kort."
            : "Du vil fra nå av motta faktura på e-post",
          type: "success",
        });
      }
      catch (e) {
        handleError(e);
        notify({
          title: "Noe gikk galt! 🤯",
          message: "Vi klarte ikke å oppdatere betalingsmåten din. Ta kontakt med support dersom problemet vedvarer.",
          type: "error",
        });
      }
      vm.loading = false;
    }

    return {
      noSubscription,
      update,
      vm,
    };
  },
});
